window.disableUntilFilled = function (inputsClass, btnSelector) {
    let inputs = document.getElementsByClassName(inputsClass);
    let button = document.querySelector(btnSelector);

    button.disabled = true;

    for (var i=0; i <= inputs.length-1; i++) {
        inputs[i].addEventListener("input", stateHandle);
    }

    function stateHandle() {
        var count = 0;
        for (var i=0; i <= inputs.length-1; i++) {
            if (inputs[i].value === "") {
                button.disabled = true;
            } else {
                count++;
            }
        }
        if (count == inputs.length) {
            button.disabled = false;
        }
    }
}

